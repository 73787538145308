import { YuxDB } from "yux-storage";
import { apiGetSyncPage, apiGetDeletePage } from "@/common/api";
import store from "@/store";
const state = store.state;
let yimutodo = null;
const deleteMap = {
  tasks: "taskId",
  taskRepeats: "taskId",
  taskNotices: "taskNoticeId",
  taskModules: "taskId",
  taskHabits: "taskId",
  taskGroups: "groupId",
  taskClockedHistories: "clockedHistoryId",
  tags: "tagId",
  stickers: "taskId",
  projectDTOS: "projectId",
  addresses: "addressId",
  absorbs: "absorbedId",
};

// const bizMap = {
//   taskNoticeIds: "taskNotices",
//   stickerIdS: "stickers",
//   tagIds: "tags",
//   absorbedIds: "absorbs",
//   addressIds: "addresses",
//   projectIds: "projectDTOS",
//   taskModuleIdS: "taskModules",
//   taskIds: "tasks",
//   taskGroupIds: "taskGroups",
//   repeatTaskIds: "taskRepeats",
//   habitTaskIds: "taskHabits",
//   clockedHistoryIds: "taskClockedHistories",
// };
const bizMap = {
  taskNotices: "taskNoticeIds",
  stickers: "stickerIdS",
  tags: "tagIds",
  absorbs: "absorbedIds",
  addresses: "addressIds",
  projectDTOS: "projectIds",
  taskModules: "taskModuleIdS",
  tasks: "taskIds",
  taskGroups: "taskGroupIds",
  taskRepeats: "repeatTaskIds",
  taskHabits: "habitTaskIds",
  taskClockedHistories: "clockedHistoryIds",
};

let isSyncing = false;
let syncPromise = null;
export const initInexDB = async () => {
  console.log("initInexDB 初始化本地数据库");
  if (state?.user?.userId) {
    yimutodo = new YuxDB(state.user.userId);
  }
};

const getCombinedData = async (time) => {
  const lastSyncTime =
    time ?? (await getlocalData({ bizType: "lastSyncTime" })) ?? 0;
  return await getData(lastSyncTime);
};
export const syncData = async (time) => {
  if (isSyncing) {
    return syncPromise;
  }

  isSyncing = true;

  syncPromise = getCombinedData(time).finally(() => {
    isSyncing = false;
    syncPromise = null;
  });

  return syncPromise;
};

export const getData = async (timer) => {
  try {
    // const res = await fetchData(timer);
    const deleteData = await fetchData(2, timer); // 同步类型，类型1为获取更新数据，类型2为获取删除数据
    const addData = await fetchData(1, timer); // 同步类型，类型1为获取更新数据，类型2为获取删除数据
    const res = await syncGetHandle(deleteData, addData);
    return res;
  } catch (error) {
    console.error("Error in getData:", error);
    return null;
  }
};

export const fetchData = async (type, syncTime) => {
  try {
    let data = null;
    if (type === 1) {
      data = await apiGetSyncPage(syncTime);
    } else {
      data = await apiGetDeletePage(syncTime);
    }

    if (data === null) {
      return null;
    }

    let result = {};

    for (const key in data) {
      if (Array.isArray(data[key])) {
        if (!result[key]) {
          result[key] = [];
        }
        result[key] = [...result[key], ...data[key]];
      } else {
        result[key] = data[key];
      }
    }

    // 如果 `anyData` 为 true，则进行递归请求
    if (data.anyData) {
      // 使用 lastSyncTime 进行递归请求
      const additionalData = await fetchData(type, data.lastSyncTime);

      for (const key in additionalData) {
        if (Array.isArray(additionalData[key])) {
          if (!result[key]) {
            result[key] = [];
          }
          result[key] = [...result[key], ...additionalData[key]];
        } else {
          result[key] = additionalData[key];
        }
      }
    }

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {};
  }
};

export const syncGetHandle = async (deleteData, addData) => {
  try {
    const promises = Object.entries(addData).map(async ([key, value]) => {
      if (value === null) {
        if (
          key !== "succeed" ||
          key !== "errorMessage" ||
          key !== "errorCode" ||
          key !== "class"
        ) {
          value = [];
        }
      }

      if (Array.isArray(value)) {
        const local = (await yimutodo.getItem(key)) ?? [];
        let mergedArray = [...local, ...value];
        // console.log(mergedArray)
        const seen = new Set();
        mergedArray = mergedArray.reverse().filter((obj) => {
          const taskKey = obj[deleteMap[key]];

          if (taskKey) {
            if (seen.has(taskKey)) {
              return false;
            }
            seen.add(taskKey);
            return true;
          }
        });
        const deleteKey = bizMap[key];
        if (Array.isArray(deleteData[deleteKey])) {
          const deleteList = deleteData[deleteKey];
          // console.log(deleteKey, deleteList);
          // console.log(mergedArray);
          mergedArray = mergedArray.filter((obj) => {
            const taskKey = obj[deleteMap[key]];
            return !deleteList.includes(taskKey);
          });
        }
        // console.log(key, mergedArray.reverse());
        // console.log("syncData 同步任务", formatTimestamp(Date.now()));
        return await yimutodo?.setItem(key, mergedArray.reverse());
      } else {
        // console.log("syncData 同步任务", formatTimestamp(Date.now()));
        return await yimutodo?.setItem(key, value);
      }
    });

    return await Promise.all(promises);
  } catch (error) {
    console.error("Error in syncGetHandle:", error);
    return null;
  }
};

async function getRepeatDetail(taskId) {
  const taskRepeats = (await yimutodo.getItem("taskRepeats")) ?? [];
  const repeats = taskRepeats.filter((item) => {
    return item.taskIdStr === taskId;
  });

  return repeats[0] ?? {};
}
async function getRepeatList() {
  const taskRepeats = (await yimutodo.getItem("taskRepeats")) ?? [];
  return taskRepeats;
}

async function getNoticeDetail(taskId) {
  const taskNotices = (await yimutodo.getItem("taskNotices")) ?? [];
  const notices = taskNotices.filter((item) => {
    return item.taskIdStr === taskId;
  });
  return notices;
}

export const getlocalData = async ({ bizType, projectId, taskId }) => {
  if (projectId) {
    projectId = projectId.toString();
  }
  if (bizType === "habitDTO") {
    return await habitToTask();
  }

  if (bizType === "noticeDetail") {
    return await getNoticeDetail(taskId);
  }

  if (bizType === "repeatDetail") {
    return await getRepeatDetail(taskId);
  }

  if (bizType === "repeatList") {
    return await getRepeatList();
  }

  if (bizType === "taskGroups") {
    const taskGroups = (await yimutodo.getItem(bizType)) ?? [];
    return taskGroups.filter((item) => {
      return item.projectIdStr === projectId;
    });
  }

  if (bizType === "tasks") {
    const subList = (await yimutodo.getItem(bizType)) ?? [];
    const { clockedHistoryMap, habitMap } = await habitToTask();
    const taskRepeats = (await yimutodo.getItem("taskRepeats")) ?? [];
    const taskNotices = (await yimutodo.getItem("taskNotices")) ?? [];
    subList.map((item) => {
      item.habitDTO = habitMap[item.taskIdStr];
      item.clockedHistoryDTOList = clockedHistoryMap[item.taskIdStr];
      item.hasRepeat = taskRepeats.some(
        (repeat) => repeat.taskIdStr === item.taskIdStr
      );
      item.hasNotice = taskNotices.some(
        (notice) => notice.taskIdStr === item.taskIdStr
      );
    });
    // console.log(subList, projectId, 11111)
    if (projectId && subList) {
      if ("-2" + state.user.userId === projectId) {
        return subList;
      }

      if ("-300" + state.user.userId === projectId) {
        return filterTimeData(subList, -3);
      }

      if ("-200" + state.user.userId === projectId) {
        return filterTimeData(subList, -2);
      }

      if ("-100" + state.user.userId === projectId) {
        return filterTimeData(subList, -1);
      }

      if ("100" + state.user.userId === projectId) {
        return filterTimeData(subList, 1);
      }

      if (isTimeProject(projectId)) {
        const days = parseInt(extractBefore00(projectId), 10);
        let [startTimestamp, endTimestamp] = getTimestamps(days);
        const res = filterTasks(subList, startTimestamp, endTimestamp);
        return res;
      }

      const res = subList.filter((item) => {
        if (item.projectIdStr === projectId) {
          if (item.taskType === 2 && !item.habitDTO) {
            return false;
          }

          return true;
        }
        return false;
      });
      // console.log(res)
      return res;
    } else {
      return subList;
    }
  }

  if (bizType === "onlyTasks") {
    return (await yimutodo.getItem("tasks")) ?? [];
  }

  if (bizType === "absorbs") {
    // console.log(await yimutodo.getItem("absorbs"))
    return (await yimutodo.getItem("absorbs")) ?? [];
  }

  return (await yimutodo?.getItem(bizType) ?? []);
};
export const setlocalData = async ({ bizType, data, action, key }) => {
  //action 1代表增加以及修改 2删除
  let localData = (await yimutodo.getItem(bizType)) || [];
  if (action === 1) {
    let taskFound = false;

    localData.forEach((task, index) => {
      if (task[key] === data[key]) {
        localData[index] = JSON.parse(JSON.stringify(data));
        taskFound = true;
      }
    });

    if (!taskFound) {
      localData.push(JSON.parse(JSON.stringify(data)));
    }
    // console.log({ bizType, data, action, key });
    // console.log(localData);
    return await yimutodo?.setItem(bizType, localData);
  }
  if (action === 2) {
    localData = localData.filter((item) => item[key] !== data[key]);

    return await yimutodo?.setItem(bizType, localData);
  }
};

export const clearData = async () => {
  return await yimutodo?.clear();
};

const extractBefore00 = (id) => {
  if (state.user.userId) {
    const userId = state.user.userId;
    const regex = new RegExp(`^(\\d{1,3})00(${userId})$`);
    const match = id.match(regex);

    if (match) {
      const randomNumber = parseInt(match[1], 10);
      return randomNumber;
    }
    return 365;
  }
  return 365;
};

const habitToTask = async () => {
  const clockedHistoryDTOList =
    (await yimutodo.getItem("taskClockedHistories")) ?? [];
  const habitDTO = (await yimutodo.getItem("taskHabits")) ?? [];

  const clockedHistoryMap = clockedHistoryDTOList.reduce((acc, item) => {
    if (!acc[item.taskIdStr]) {
      acc[item.taskIdStr] = [item];
    } else {
      acc[item.taskIdStr].push(item);
    }

    return acc;
  }, {});

  const habitMap = habitDTO.reduce((acc, item) => {
    acc[item.taskIdStr] = item;
    return acc;
  }, {});

  return { clockedHistoryMap, habitMap };
};

const filterTimeData = (list, type) => {
  const now = new Date();
  let startTimestamp, endTimestamp;

  if (type === -3) {
    // 明天
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const endOfTomorrow = new Date(tomorrow);
    endOfTomorrow.setHours(23, 59, 59, 999);

    startTimestamp = tomorrow.getTime();
    endTimestamp = endOfTomorrow.getTime();
  } else if (type === 1) {
    // 今天
    const startOfToday = new Date(now);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(startOfToday);
    endOfToday.setHours(23, 59, 59, 999);

    startTimestamp = startOfToday.getTime();
    endTimestamp = endOfToday.getTime();
  } else if (type === -1) {
    // 本周
    const now = new Date(); // 假设现在的时间是 now
    const startOfWeek = new Date(now);

    // 计算本周一的日期
    startOfWeek.setDate(now.getDate() - ((now.getDay() + 6) % 7)); // 0是周日，所以这里使用 (now.getDay() + 6) % 7
    startOfWeek.setHours(0, 0, 0, 0); // 设置为00:00:00.000

    const endOfWeek = new Date(startOfWeek);
    // 计算本周日的日期
    endOfWeek.setDate(startOfWeek.getDate() + 6); // 本周日是周一加6天
    endOfWeek.setHours(23, 59, 59, 999); // 设置为23:59:59.999

    startTimestamp = startOfWeek.getTime();
    endTimestamp = endOfWeek.getTime();
  } else if (type === -2) {
    // 本月
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    startTimestamp = startOfMonth.getTime();
    endTimestamp = endOfMonth.getTime();
  } else {
    return list; // 其他类型不处理，直接返回原数据
  }

  // console.log(list, startTimestamp, endTimestamp);
  const res = filterTasks(list, startTimestamp, endTimestamp);

  return res;
};

const filterTasks = (tasks, startTime, endTime) => {
  // console.log(tasks, startTime, endTime);
  return tasks.filter((task) => {
    const taskStartTime = task.startTime;
    const taskEndTime = task.endTime;
    const taskType = task.taskType;
    const taskCompleteTime = task.completeTime;
    const taskIsTop = task.isTop;
    const taskDataDelete = task.dataDelete;
    const isExpire = isTaskInTimeRange(
      startTime,
      endTime,
      taskStartTime,
      taskEndTime
    );
    // console.log(
    //   taskType,
    //   task.title,
    //   taskCompleteTime,
    //   taskIsTop,
    //   taskDataDelete,
    //   startTime,
    //   endTime,
    //   taskStartTime,
    //   taskEndTime,
    //   isExpire
    // );

    const isTaskValid =
      !taskCompleteTime &&
      !taskIsTop &&
      !taskDataDelete &&
      ((taskType === 0 && taskStartTime && isExpire) ||
        (taskType === 2 && taskStartTime && taskStartTime < endTime) ||
        (taskType === 1 &&
          taskStartTime > startTime &&
          taskStartTime < endTime));
    //     &&
    // (!taskEndTime || taskEndTime >= endTime);

    const isParentTaskValid = true;
    // !taskParentId ||
    // !tasks.some((parentTask) => {
    //   console.log(parentTask)
    //   console.log(
    //     parentTask.title,
    //     parentTask.completeTime,
    //     parentTask.dataDelete,
    //     parentTask.taskType,
    //     parentTask.startTime,
    //     parentTask.startTime < endTime
    //   );
    //   return (
    //     !parentTask.completeTime &&
    //     !parentTask.isTop &&
    //     !parentTask.dataDelete &&
    //     ((parentTask.taskType !== 1 &&
    //       parentTask.startTime > 0 &&
    //       parentTask.startTime < endTime) ||
    //       (parentTask.taskType === 1 &&
    //         parentTask.startTime > startTime &&
    //         parentTask.startTime < endTime)) &&
    //     (!parentTask.endTime || parentTask.endTime >= endTime)
    //   );
    // });
    // console.log(isTaskValid, isParentTaskValid);
    // console.log(
    //   !taskCompleteTime,
    //   !taskIsTop,
    //   !taskDataDelete,
    //   taskType === 0 && taskStartTime && isExpire,
    //   !taskEndTime || taskEndTime >= endTime,
    //   isTaskValid,
    //   isParentTaskValid
    // );
    return isTaskValid && isParentTaskValid;
  });
};

const isTaskInTimeRange = (startTime, endTime, taskStartTime, taskEndTime) => {
  // 确保时间区间有效
  if (startTime === undefined || endTime === undefined) {
    throw new Error("时间区间的 startTime 和 endTime 是必需的");
  }

  // 任务开始时间和结束时间都为 null，直接过滤
  if (taskStartTime === null && taskEndTime === null) {
    return false;
  }

  // 任务结束时间为 null，使用任务开始时间判断
  if (taskEndTime === null) {
    return taskStartTime >= startTime && taskStartTime <= endTime;
  }

  // 任务开始时间为 null，直接过滤
  if (taskStartTime === null) {
    return false;
  }

  // 任务开始时间和结束时间都不为 null，判断任务活动区间是否在时间区间内
  return (
    (taskStartTime >= startTime && taskStartTime <= endTime) ||
    (taskEndTime >= startTime && taskEndTime <= endTime) ||
    (taskStartTime < startTime && taskEndTime > endTime)
  );
};

const getTimestamps = (daysAhead) => {
  const today = new Date();
  const todayMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const todayMidnightTimestamp = todayMidnight.getTime();
  const futureTime = todayMidnightTimestamp - daysAhead * 24 * 60 * 60 * 1000;
  const todayEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59
  );
  const todayEndTimestamp = todayEnd.getTime();

  return [futureTime, todayEndTimestamp];
};
const isTimeProject = (id) => {
  if (state.user.userId) {
    const userId = state.user.userId;
    const regex = new RegExp(`^(\\d{1,3})00(${userId})$`);
    id = id.toString();
    const match = id.match(regex);

    if (match) {
      const randomNumber = parseInt(match[1], 10);
      return randomNumber >= 1 && randomNumber <= 365;
    }

    return false;
  }
};
