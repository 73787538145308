<template>
  <Split 
    class="manager"
    v-model="siderSplit.split" 
    :min="siderSplit.min" 
    :max="siderSplit.max"
    @on-move-end="onSplitMoveEnd">
    <template #left>
      <XMenu />
    </template>
    <template #trigger>
      <div class="trigger-bg">
        <div class="trigger" style="background-color: transparent"></div>
      </div>
    </template>
    <template #right>
      <router-view style="height: 100%; width: 100%; box-sizing: border-box;"></router-view>
    </template>
  </Split> 
</template>

<script>
/// 工作台主页面
import { userInfo, iconList } from '@/common/api'
import XMenu from '@/components/XMenu';

const siderSplitKey = 'sider-split';

export default {
  components: {
    XMenu
  },
  data() {
    return {
      siderSplit: {
        split: '240px',
        min: '240px',
        max: document.documentElement.clientWidth - 480 + "px"
      },
    };
  },
  methods: {
    onSplitMoveEnd() {
      localStorage.setItem(siderSplitKey, this.realWidth(this.siderSplit.split));
    },
    realWidth(widthPx) {
      var min = 240;
      var max = document.documentElement.clientWidth - 480;
      var widthStr = widthPx.replace('px', '');
      var width = parseInt(widthStr);
      if (width < min) {
        width = min;
      } else if (width > max) {
        width = max;
      }
      return width + 'px';
    }
  },
  async created() {
    var widthPx = localStorage.getItem(siderSplitKey) || '240px';
    this.siderSplit.split = this.realWidth(widthPx);
    const updateInfo = await userInfo()
    this.$store.commit("updateInfo", updateInfo);
    const updateIcons = await iconList()
    this.$store.commit('updateIcons', updateIcons);
  }
}
</script>

<style lang="less" scoped>
  .manager {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    :deep(.ivu-list-items){
      border: none;
    }
    .trigger-bg {
      padding: 0 2px;
      height: 100%;
      .trigger {
        width: 3px;
        height: 100%;
        background-color: transparent;
        cursor: col-resize;
      }
    }
  }
</style>