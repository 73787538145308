<template>
  <div class="login-check">
    <Spin fix></Spin>
  </div>
</template>

<script>
  import { loginThird } from "@/common/api"
  import { syncData, initInexDB } from "@/common/syncUntil";
  export default {
    created() {
      // QQ登录返回值 ?code=xxxxxxx&state=QQ
      // 微博登录返回值 ?code=xxxxxxx
      // 微信登录返回值 ?code=xxxxxxx&state=WECHAT
      let state = this.$route.query.state;
      let code = this.$route.query.code;

      // let accessToken = this.$route.query.access_token;
      // if (accessToken) {
      //   state = 'QQ';
      //   code = accessToken;
      // } else if (window.location.href.indexOf('access_token') > -1) {
      //   state = 'QQ';
      //   let href = window.location.href;
      //   let list = href.split('&');
      //   list.forEach(element => {
      //     if (element.indexOf('access_token') > -1) {
      //       let li = element.split('=');
      //       code = li[li.length - 1];
      //     }
      //   });
      // }

      if (!state || state.length == 0) {
        state = 'WEIBO';
      }
      if (state && code) {
        loginThird(code, state).then( async res => {
          if (res) {
            this.$store.commit("updateInfo", res);
            await initInexDB();
            await syncData();
            this.$router.replace("/manager/work");
          }
        });
      } else {
        this.$Message.error('第三方登录参数有误');
        this.$router.replace({name: 'primary'});
      }
    }
  }
</script>

<style lang="less" scoped>
  .login-check {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
  }
</style>