<template>
  <div class="setting">
    <Button
      class="setting-btn back"
      type="default"
      size="large"
      @click="onBackClick"
    >
      <Icon type="md-arrow-back" />&nbsp;&nbsp;返回
    </Button>
    <div class="setting-content">
      <div style="height: 80px"></div>
      <h5 class="title">提醒设置</h5>

      <div class="setting-cell">
        <div>
          <p>
            微信提醒<XIcon iconClass="user_member" style="margin-left: 13px" />
          </p>
          <p v-if="wechatInfo">
            <Icon type="ios-information-circle" style="color: #42b4ff" />{{
              wechatInfo.openNickName
            }}，请关注公众号“一木宇宙”，仅对新设置生效
          </p>
          <p v-else>
            <Icon type="ios-information-circle" style="color: #42b4ff" />需<span
              style="color: rgba(88, 119, 250, 1)"
              @click="onWechatClick"
              >绑定微信账号</span
            >，关注公众号“一木宇宙”，仅对新设置生效
          </p>
        </div>
        <Switch v-model="isWechatNotice" :before-change="changeWechatNotice" />
      </div>
      <div class="setting-cell">
        <div>
          <p>
            邮件提醒<XIcon iconClass="user_member" style="margin-left: 13px" />
          </p>
          <span
            v-if="customEmail"
            @click="onChangeEmailClick"
            style="cursor: pointer"
            ><Icon type="ios-information-circle" style="color: #42b4ff" />{{
              customEmail
            }}</span
          >
          <span v-else style="cursor: pointer"
            ><Icon
              type="ios-information-circle"
              style="color: #42b4ff"
            />请<span style="color: rgba(88, 119, 250, 1)" @click="onEmailClick"
              >绑定邮箱</span
            ></span
          >
        </div>
        <Switch v-model="isEmailNotice" :before-change="changeEmailNotice" />
      </div>
      <div class="setting-cell">
        <div>
          <p>本地日历提醒</p>
          <p>
            <Icon
              type="ios-information-circle"
              style="color: #42b4ff"
            />将未来3天的任务添加至系统日历进行提醒
          </p>
        </div>
        <Switch
          v-model="isCalendarNotice"
          :before-change="changeCalendarNotice"
          disabled
        />
      </div>
      <div class="setting-cell" v-if="!isElectron">
        <div>
          <p>是否开启系统通知</p>
          <p>
            <Icon
              type="ios-information-circle"
              style="color: #42b4ff"
            />允许网页以系统级别发送在页面外部显示的通知(若要关闭通知，在浏览器通知设置里关闭)
          </p>
        </div>
        <Switch
          v-model="usingNotification"
          :before-change="changeNotification"
          :disabled="usingNotification"
        />
      </div>
    </div>
  </div>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showNickModal"
    width="420px"
    title="修改昵称"
    @on-ok="onNickModalOk"
  >
    <Input
      style="margin: 24px 0px 30px 0px"
      v-model="nickName"
      placeholder="请输入昵称"
    />
  </Modal>
  <Modal
    title="修改头像"
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showAvatarModal"
    width="420px"
    @on-ok="onAvatarModalOk"
  >
    <div style="display: flex">
      <div style="width: 230px; height: 230px; margin: 24px 30px 0px 0px">
        <vueCropper
          style="margin-bottom: 20px"
          ref="cropper"
          :img="options.img"
          :autoCrop="options.autoCrop"
          :fixed="options.fixed"
          :centerBox="options.centerBox"
          @realTime="realTime"
        ></vueCropper>
        <Upload
          ref="upload"
          action=""
          :show-upload-list="false"
          accept="image/png, image/jpeg, image/jpg"
          :format="['jpg', 'jpeg', 'png']"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :before-upload="handleBeforeUpload"
        >
          <span class="primary-color hand">{{
            options.img.length > 0 ? "重新上传" : "选择图片"
          }}</span>
        </Upload>
      </div>
      <div style="width: 90px; margin-top: 24px">
        <div v-if="previewInfo.url" :style="previewStyle">
          <div :style="previewInfo.div">
            <Image :src="previewInfo.url" :style="previewInfo.img" />
          </div>
        </div>
        <Image
          v-else-if="userInfo.avatarUrl"
          :src="userInfo.avatarUrl"
          with="90"
          style="border-radius: 45px; overflow: hidden"
        />
        <p
          style="margin-top: 20px; font-size: 14px; text-align: center"
          class="default-color"
        >
          头像预览
        </p>
      </div>
    </div>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showEmailModal"
    width="420px"
    @on-ok="onEmailModalOk"
    title="绑定邮箱"
  >
    <Form
      style="margin-top: 50px"
      ref="form"
      :model="formdata"
      :rules="rules"
      label-position="left"
      :label-width="80"
    >
      <FormItem prop="email" label="邮箱">
        <Input
          :border="false"
          size="large"
          type="email"
          v-model="formdata.email"
          placeholder="请输入邮箱"
        ></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input
          style="flex: 1"
          :border="false"
          size="large"
          type="text"
          v-model="formdata.captcha"
          placeholder="请输入验证码"
        >
          <template #suffix>
            <Button
              type="text"
              class="captcha-button"
              @click="captchaClick(0)"
              >{{ seconds == 60 ? "获取验证码" : seconds }}</Button
            >
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="密码">
        <Input
          :border="false"
          size="large"
          :type="showPassword1 ? 'text' : 'password'"
          v-model="formdata.password"
          placeholder="请输入密码,6-20个字符"
        >
          <template #suffix>
            <div class="hand" @click="showPassword1 = !showPassword1">
              <Icon
                v-if="showPassword1"
                style="line-height: 48px"
                type="md-eye"
                class="default-color"
              />
              <XIcon
                v-else
                iconClass="eye"
                size="16"
                style="margin: 16px 0px"
              />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="确认密码">
        <Input
          :border="false"
          size="large"
          :type="showPassword2 ? 'text' : 'password'"
          v-model="formdata.passwordConfirm"
          placeholder="请确认密码"
        >
          <template #suffix>
            <div class="hand" @click="showPassword2 = !showPassword2">
              <Icon
                v-if="showPassword2"
                style="line-height: 48px"
                type="md-eye"
                class="default-color"
              />
              <XIcon
                v-else
                iconClass="eye"
                size="16"
                style="margin: 16px 0px"
              />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showEmailUnbindModal"
    width="420px"
    @on-ok="onEmailUnbindModalOk"
    title="解除绑定"
  >
    <Form
      style="margin-top: 50px"
      ref="unbindForm"
      :model="formdata"
      :rules="unbindRules"
      label-position="left"
      :label-width="80"
    >
      <FormItem prop="email" label="邮箱">
        <Input
          readonly
          :border="false"
          size="large"
          type="email"
          v-model="formdata.email"
          placeholder="请输入邮箱"
        ></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input
          style="flex: 1"
          :border="false"
          size="large"
          type="text"
          v-model="formdata.captcha"
          placeholder="请输入验证码"
        >
          <template #suffix>
            <Button
              type="text"
              class="captcha-button"
              @click="captchaClick(2)"
              >{{ seconds == 60 ? "获取验证码" : seconds }}</Button
            >
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showModifyModal"
    width="420px"
    @on-ok="onModifyModalOk"
    title="修改密码"
  >
    <Form
      style="margin-top: 50px"
      ref="form"
      :model="formdata"
      :rules="modifyRules"
      label-position="left"
      :label-width="80"
    >
      <FormItem prop="password" label="当前密码">
        <Input
          :border="false"
          size="large"
          :type="showPassword1 ? 'text' : 'password'"
          v-model="formdata.password"
          placeholder="请输入当前密码"
        >
          <template #suffix>
            <div class="hand" @click="showPassword1 = !showPassword1">
              <Icon
                v-if="showPassword1"
                style="line-height: 48px"
                type="md-eye"
                class="default-color"
              />
              <XIcon
                v-else
                iconClass="eye"
                size="16"
                style="margin: 16px 0px"
              />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="新密码">
        <Input
          :border="false"
          size="large"
          :type="showPassword2 ? 'text' : 'password'"
          v-model="formdata.passwordConfirm"
          placeholder="请输入新密码"
        >
          <template #suffix>
            <div class="hand" @click="showPassword2 = !showPassword2">
              <Icon
                v-if="showPassword2"
                style="line-height: 48px"
                type="md-eye"
                class="default-color"
              />
              <XIcon
                v-else
                iconClass="eye"
                size="16"
                style="margin: 16px 0px"
              />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showThirdUnbindModal"
    width="420px"
    @on-ok="onThirdUnbindModalOk"
    title="解除绑定"
  >
    <p style="margin: 20px 0px 50px 0px; font-size: 14px">
      解除绑定后，将无法使用{{ typeKeys[unbindInfo.type] }}账号：“{{
        unbindInfo.openNickName
      }}”登录并关联当前账号。<br />确定要解除绑定吗？
    </p>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showErrorUnbindModal"
    width="420px"
    title="解除绑定"
  >
    <p style="margin: 20px 0px 50px 0px; font-size: 14px">
      当前{{
        typeKeys[unbindInfo.type]
      }}账号为初始注册账户，无法进行解绑，如要彻底删除，请点击底部注销账号。
    </p>
  </Modal>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showDestroyModal"
    width="420px"
    @on-ok="onDestroyModalOk"
    title="注销账号"
  >
    <p style="margin: 20px 0px; font-size: 14px" class="default-color">
      该操作将会永久删除当前账号，以及账号相关的所有数据。注销后账号无法再次找回。<br />
      如您确认要注销，请输入以下确认码：
    </p>
    <p
      style="margin-bottom: 20px; font-size: 16px; text-align: center"
      class="default-color"
    >
      {{ confirmCode }}
    </p>
    <Input
      :maxlength="6"
      style="margin-bottom: 50px"
      v-model="confirmInput"
      placeholder="请输入确认码"
    />
  </Modal>

  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showWechatModal"
    width="420px"
    footer-hide
    title="绑定微信"
  >
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      "
    >
      <Image
        src=""
        style="width: 180px; height: 180px; background: rgba(204, 204, 204, 1)"
      />
    </div>
    <p
      style="font-size: 18px; margin-top: 30px; text-align: center"
      class="default-color"
    >
      使用微信扫描二维码
    </p>
    <p
      style="font-size: 14px; margin-top: 16px; text-align: center"
      class="default-color"
    >
      使用微信扫描二维码，登录一木清单
    </p>
  </Modal>

  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"
    v-model="showEmailEditModal"
    width="420px"
    @on-ok="onEmailEditModalOk"
    title="配置邮箱"
  >
    <Input
      style="margin-top: 20px"
      v-model="inputStr"
      type="email"
      placeholder="请输入邮箱"
    />
  </Modal>
</template>

<script>
let isElectron = process.env.IS_ELECTRON;
import {
  userInfo,
  modifyInfo,
  ossUpload,
  captcha,
  bindEmail,
  unbindEmail,
  unbindThird,
  userDestroy,
  modifyPassword,
  modifyConfigInfo,
  userConifgInfo,
} from "@/common/api";
import "vue-cropper/dist/index.css";
// import { VueCropper }  from "vue-cropper";

export default {
  components: {
    // VueCropper
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(new Error("两次输入的密码不匹配！"));
      } else {
        callback();
      }
    };
    return {
      isElectron,
      typeKeys: {
        QQ: "QQ",
        WECHAT: "微信",
        WEIBO: "微博",
        EMAIL: "邮箱",
      },
      usingNotification: false,
      confirmCode: "123456",
      confirmInput: "",

      showNickModal: false,
      nickName: "",

      options: {
        img: "", // 原图文件
        autoCrop: true, // 默认生成截图框
        fixedBox: false, // 固定截图框大小
        canMoveBox: true, // 截图框可以拖动
        autoCropWidth: 180, // 截图框宽度
        autoCropHeight: 180, // 截图框高度
        fixed: true, // 截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        centerBox: true, // 截图框被限制在图片里面
        canMove: false, // 上传图片不允许拖动
        canScale: false, // 上传图片不允许滚轮缩放
      },
      previewStyle: "",
      previewInfo: {},
      showAvatarModal: false,

      seconds: 60,
      showEmailModal: false,
      formdata: {},
      showPassword1: false,
      showPassword2: false,
      showModifyModal: false,
      rules: {
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            max: 20,
            message: "请输入六到二十位的密码",
            trigger: "blur",
          },
        ],
        passwordConfirm: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" },
        ],
      },
      unbindRules: {
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      modifyRules: {
        password: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
        passwordConfirm: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            max: 20,
            message: "请输入六到二十位的新密码",
            trigger: "blur",
          },
        ],
      },

      unbindInfo: {},
      showThirdUnbindModal: false,
      showEmailUnbindModal: false,
      showErrorUnbindModal: false,
      showWechatModal: false,

      showDestroyModal: false,
      showEmailEditModal: false,
      configs: [],
      customEmail: "",
      inputStr: "",
    };
  },
  async mounted() {
    if (!this.userInfo.userId) {
      await this.loadInfo();
    } else {
      this.nickName = this.userInfo.nickName;
      this.customEmail = this.userInfo.email;
    }
    userConifgInfo().then((res) => {
      this.configs = res;
      const emailInfo = this.configs.find(
        (item) => item.type == "NOTICE_EMAIL_SWITCH"
      );
      if (emailInfo && emailInfo.value != "false") {
        this.customEmail = emailInfo.value;
      }
    });
    if (window.Notification && Notification.permission === "granted") {
      this.setNotificationHandle(true);
    }
  },
  computed: {
    isWechatNotice() {
      const wechatInfo = this.configs.find(
        (item) => item.type == "NOTICE_WECHAT_SWITCH"
      );
      return wechatInfo?.value == "true";
    },
    isEmailNotice() {
      const emailInfo = this.configs.find(
        (item) => item.type == "NOTICE_EMAIL_SWITCH"
      );
      if (emailInfo?.value) {
        if (emailInfo?.value != "false") {
          return true;
        }
      }
      return false;
    },
    isCalendarNotice() {
      const calendarInfo = this.configs.find(
        (item) => item.type == "NOTICE_CALENDAR_SWITCH"
      );
      return calendarInfo?.value == "true";
    },
    qqInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0)
        return null;
      return this.userInfo.openInfos.find((item) => item.type == "QQ");
    },
    wechatInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0)
        return null;
      return this.userInfo.openInfos.find((item) => item.type == "WECHAT");
    },
    weiboInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0)
        return null;
      return this.userInfo.openInfos.find((item) => item.type == "WEIBO");
    },
    userInfo() {
      return this.$store.state.user;
    },
    memberTitle() {
      let memberType = this.userInfo.memberType;
      switch (memberType) {
        case "NORMAL":
          return "普通用户";
        case "MONTH":
          return "月度会员";
        case "YEAR":
          return "年度会员";
        case "PERMANENT":
          return "永久会员";
        default:
          return "普通用户";
      }
    },
  },
  methods: {
    changeWechatNotice() {
      return new Promise((resolve) => {
        modifyConfigInfo("NOTICE_WECHAT_SWITCH", !this.isWechatNotice).then(
          (res) => {
            if (res) {
              resolve();
            }
          }
        );
      });
    },
    changeEmailNotice() {
      return new Promise((resolve) => {
        if (this.isEmailNotice) {
          modifyConfigInfo("NOTICE_EMAIL_SWITCH", "false").then((res) => {
            if (res) {
              resolve();
            }
          });
        } else {
          modifyConfigInfo("NOTICE_EMAIL_SWITCH", this.customEmail).then(
            (res) => {
              if (res) {
                resolve();
              }
            }
          );
        }
      });
    },
    changeNotification() {
      this.$nextTick(() => {
        if (this.usingNotification) {
          this.setNotification();
        }
      });
    },
    setNotificationHandle(bool) {
      this.$nextTick(() => {
        this.usingNotification = bool;
      });
    },
    setNotification() {
      if (window.Notification && Notification.permission === "granted") {
        new Notification("推送消息", {
          icon: "/app.ico",
          body: "可以接受操作系统的推送喽~",
        });
        this.setNotificationHandle(true);
      } else if (window.Notification && Notification.permission !== "denied") {
        Notification.requestPermission((status) => {
          if (Notification.permission !== status) {
            Notification.permission = status;
          }
          if (status === "granted") {
            new Notification("推送消息", {
              icon: "/app.ico",
              body: "可以接受操作系统的推送喽~",
            });
            this.setNotificationHandle(true);
          } else {
            this.$Message.error("消息推送授权未通过");
            this.setNotificationHandle(false);
          }
        });
      } else {
        this.$Message.error("消息推送授权未通过");
        this.setNotificationHandle(false);
      }
    },
    changeCalendarNotice() {
      return new Promise((resolve) => {
        modifyConfigInfo("NOTICE_CALENDAR_SWITCH", !this.isCalendarNotice).then(
          (res) => {
            if (res) {
              resolve();
            }
          }
        );
      });
    },
    onEmailEditModalOk() {
      this.customEmail = this.inputStr;
      modifyConfigInfo("NOTICE_EMAIL_SWITCH", this.customEmail);
    },
    onChangeEmailClick() {
      this.inputStr = this.customEmail;
      this.showEmailEditModal = true;
    },
    onBackClick() {
      this.$router.go(-1);
    },
    onComplete() {
      this.$router.push("/manager/work");
    },
    onMenuSelect(name) {
      this.activeName = name;
      this.$router.push({ name });
    },
    onNickClick() {
      this.showNickModal = true;
    },
    onNickModalOk() {
      if (this.nickName.length == 0) return;
      modifyInfo(this.nickName).then((res) => {
        if (res) {
          this.userInfo.nickName = this.nickName;
        }
      });
    },
    onAvatarClick() {
      this.showAvatarModal = true;
    },
    handleFormatError() {
      this.$Message.error("请选择图片");
    },
    handleMaxSize() {
      this.$Message.error("图片最大为2M");
    },
    handleBeforeUpload(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.options.img = e.target.result; // base64
      };
    },
    realTime(data) {
      this.previewStyle = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / data.w,
        borderRadius: data.w / 2 + "px",
      };
      this.previewInfo = data;
    },
    onAvatarModalOk() {
      if (this.previewInfo.img) {
        this.$refs.cropper.getCropData((data) => {
          let fileName =
            this.$store.state.user.userId + "/" + Date.now() + "avatar.jpg";
          let file = this.blobToFile(this.dataURLtoBlob(data), fileName);
          ossUpload(fileName, file).then((res) => {
            if (res) {
              this.options.img = "";
              this.previewStyle = {};
              this.previewInfo = {};

              this.userInfo.avatar = fileName;
              this.userInfo.avatarUrl = res.url;
              modifyInfo(null, fileName);
            }
          });
        });
      }
    },
    // base64转Blob
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // Blob转file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    onModifyClick() {
      this.showPassword1 = false;
      this.showPassword2 = false;
      this.formdata = {};
      this.showModifyModal = true;
    },
    onEmailClick() {
      this.showPassword1 = false;
      this.showPassword2 = false;
      this.formdata = {};
      this.showEmailModal = true;
    },
    onWechatClick() {
      if (this.wechatInfo) {
        if (this.wechatInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.wechatInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info("抱歉，暂时需要前往APP绑定第三方账号~");
      }
    },
    onQQClick() {
      if (this.qqInfo) {
        if (this.qqInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.qqInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info("抱歉，暂时需要前往APP绑定第三方账号~");
      }
    },
    onWeiboClick() {
      if (this.weiboInfo) {
        if (this.weiboInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.weiboInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info("抱歉，暂时需要前往APP绑定第三方账号~");
      }
    },
    onEmailModalOk() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          bindEmail(
            this.formdata.email,
            this.formdata.password,
            this.formdata.captcha
          ).then((res) => {
            if (res) {
              this.userInfo.email = this.formdata.email;
            }
          });
        }
      });
    },
    captchaClick(type = 0) {
      if (this.seconds < 60) return;
      this.$refs["form"].validateField("email", (valid) => {
        if (!valid) {
          captcha(this.formdata.email, type);
          var interval = setInterval(() => {
            if (this.seconds == 1) {
              this.seconds = 60;
              clearInterval(interval);
            } else {
              this.seconds--;
            }
          }, 1000);
        }
      });
    },
    onModifyModalOk() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          modifyPassword(
            this.formdata.password,
            this.formdata.passwordConfirm
          ).then((res) => {
            if (res) {
              this.$Message.success("修改密码成功~");
            }
          });
        }
      });
    },
    onEmailUnbindModalOk() {
      unbindEmail(this.formdata.captcha).then((res) => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    onThirdUnbindModalOk() {
      unbindThird(this.unbindInfo.type, this.unbindInfo.openId).then((res) => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    async loadInfo() {
      const res = await userInfo();
      this.$store.commit("updateInfo", res);
      this.nickName = this.userInfo.nickName;
    },
    onDestroyClick() {
      let random = Math.random() * (999999 - 100000) + 100000;
      this.confirmCode = Math.floor(random);
      this.showDestroyModal = true;
    },
    onDestroyModalOk() {
      if (this.confirmCode == this.confirmInput) {
        userDestroy().then((res) => {
          if (res) {
            this.$router.replace({ name: "login" });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  width: 100%;
  height: 100%;
  background-color: var(--vp-c-bg-alt);
  color: var(--vp-c-text-1);
  position: relative;
  .setting-btn {
    border-radius: 8px;
    font-size: 14px;
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-1);
    &.back {
      position: absolute;
      top: 24px;
      left: 24px;
    }
    &.high {
      color: #ff8f8f;
      border-color: #ff8f8f;
    }
  }
  .user-title {
    .nick-name {
      font-weight: 500;
      font-size: 14px;
      color: var(--vp-c-text-1);
    }
    .member-title {
      color: var(--vp-c-text-3);
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .setting-content {
    // height: calc(100% - 120px);
    height: 100%;
    max-width: 1024px;
    margin: 0px auto;
    padding: 30px;
    background-color: var(--vp-c-bg);
    border-radius: 8px;
    overflow-y: scroll;
    .base-info {
      margin-top: 30px;
      font-weight: 500;
      font-size: 20px;
    }
    .member-tip {
      margin-top: 20px;
      text-align: center;
      width: 100%;
      height: 60px;
      border-radius: 8px;
      line-height: 60px;
      color: var(--vp-c-text-1);
      background: linear-gradient(
        135deg,
        var(--ng-gradient-start) 0%,
        var(--ng-gradient-end) 100%
      );
    }
    .setting-cell {
      border-bottom: 1px solid var(--vp-c-border);
      display: flex;
      padding: 30px 0;
      align-items: center;
      color: var(--vp-c-text-1);
      :deep(.ivu-select-dropdown) {
        border: 1px solid var(--vp-c-divider);
        background-color: var(--vp-c-bg-soft);
      }
      .dropdown-list {
        background-color: var(--vp-c-bg-soft);
        color: var(--vp-c-text-1);
        :deep(.ivu-dropdown-item) {
          color: var(--vp-c-text-2);
          &:hover {
            background-color: var(--vp-c-bg);
            color: var(--vp-c-text-1);
          }
        }
      }
      div:first-child {
        flex: 1;
        p:first-child {
          font-size: 14px;
          color: var(--vp-c-text-1);
          font-weight: 500;
          margin-bottom: 8px;
        }
        p:last-child {
          font-size: 14px;
          color: var(--vp-c-text-3);
          i:first-child {
            margin-right: 4px;
          }
        }
      }
    }
    .account {
      .attach {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
}

:deep(.ivu-dropdown) {
  // display: flex;
  // align-items: center;
  cursor: pointer;
}
:deep(.ivu-input) {
  border-radius: 8px;
  background-color: var(--vp-c-bg);
  color: var(--vp-c-text-1);
  height: 48px;
}
:deep(.ivu-form-item-label) {
  height: 48px;
  line-height: 28px !important;
}
:deep(.ivu-input-suffix) {
  width: 102px;
}
.default-title {
  color: var(--vp-c-text-1);
  font-size: 16px;
}
.default-color {
  color: var(--vp-c-text-1);
}
</style>
